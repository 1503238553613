.project_gallery_parent{
 
   
    display: flex;

    gap:45px;
 
}




@media only screen and (max-width:1050px){
    /* .project_gallery_parent{
        height: auto;
        width: auto;
        display: flex;
        flex-direction: row;
        gap:45px;
     
    } */


    .project_gallery_parent {
        height: auto;
        width: auto;
        display: flex;
        flex-direction: row;
        gap: 2%;
        justify-content: center;
    }
    
}