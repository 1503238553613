.project_overlay_parent {

    background-color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    overflow-x: hidden;
    position: fixed;
    overflow-y: auto;
    height: 100vh;
    z-index: 21;
}
.bg_image_container{
    position: absolute;
    height: 772px;
    width: 289px;
    margin-right: 35%;
    border-radius: 15px;

    border: 1px solid rgba(119, 149, 255, 0.05);
    border-radius: 15px;
}

.bg_image{
    height: auto;
    width: 100%;
    opacity: 5%;
}
.project_overlay_contents{
    display: flex;
    justify-content: center;
    align-items: center;
    width:80vw;

    gap:15%;
    gap:102px;
    padding-top: 275px;
    padding-bottom: 175px;
}
.project_display_card_right_items{
    display: flex;
    flex-direction: column;
}
.project_overlay_close_button{
    color: white;
    cursor: pointer;
    align-self: flex-start;
    justify-self: flex-end;
    font-size: 45px;
    position: absolute;
  
}

.project_overlay_close_button:hover{
    color:var(--accent-color-2);
}

.project_display_card_right_items .main_header {
    text-align: left;
    width: 318px;
    margin-left: -32px;
    line-height: 58px;
   
}

.sections_parent{
    display: flex;
    flex-direction: column;
    gap: 25px;
    margin-top: 10%;
}
.roles_container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.project_display_accent_header{
    font-weight: 400;
    font-size: 15px;
    line-height: 13px;
    letter-spacing: .02em;
    color: #7FA5BA;
    font-family: "MBFLigioneRegular";
    text-align: left;
}

.project_display_accent_roles{
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: .02em;
    color: #ffffff;
    font-family: "MBFLigioneRegular";
    text-align: left;
    max-width: 388px;
}

.project_display_description{
    font-weight: 400;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: .02em;
    color: #ffffff;
    font-family: "MBFLigioneRegular";
    text-align: left;
    max-width: 388px;
}
.tech_container{
    display: flex;
    flex-direction: column;
    gap: 14px;
}
.tech_container .tech_stack_array_container{
    height:fit-content;
    width: auto;
    gap:1%
}
.tech_container .tech_stack_array_container .tech_image_parent{
    width: 21px;
    height: 21px;
}


.project_buttons_container{
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    gap:10px;
}

.project_overlay_contents .exit_button{
    top: 95px;
}
@media only screen and (max-width:1050px){

    .project_overlay_contents .exit_button {
        top: 35px;
        right: 30px;
    }
    .project_overlay_contents{
        flex-direction: column;
        gap: 5%;
        gap: 35px;
        padding-top: 20vh;
    }

    .project_display_card_parent {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 450px;
        width: 100%;
        max-width: 416px;
        left: 20px;
        top: 20px;
        border-radius: 0px;
        background-color: rgba(71, 50, 50, 0);
        margin-top: 45vh;
    }


    .project_display_card_background {
        box-sizing: border-box;
        position: absolute;
        width: 27%;
        height: 48%;
        border: 1px solid rgba(162, 89, 255, 0.25);
        border-radius: 5px;
        min-width: 270px;
    }

    .project_display_card_image_container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        position: absolute;
        width: 25%;
        height: 35%;
        height: 45%;
        border: 1px solid #A259FF;
        border-radius: 15px;
        object-fit: cover;
        overflow: hidden;
        min-width: 250px;
    }


    .project_display_card_image {
        width: 100%;
        height: auto;
    }

    .project_display_card_shadow {
        position: absolute;
        width: 306px;
        height: 15px;
        background: #401760;
        filter: blur(17.5px);
        align-self: flex-end;
        margin-bottom: -2px;
        opacity: .35;
    }

.project_display_card_left_items{
    height: fit-content;
}

    .project_display_card_right_items {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project_display_card_right_items .main_header {
        text-align: left;
        width: 318px;
        margin-left: -32px;
        line-height: 58px;
        text-align: center;
    }


    .project_display_accent_header {

        text-align: center;
    }
    .project_display_accent_roles {

        text-align: center;

    }
    .project_display_description {

        text-align: center;

    }

    .tech_container .tech_stack_array_container {
        height: -moz-fit-content;
        height: fit-content;
        width: auto;
        gap: 1%;
        justify-content: center;
    }


    .project_overlay_close_button {
        color: white;
        cursor: pointer;
        align-self: flex-start;
        justify-self: flex-end;
        font-size: 45px;
        position: absolute;
        top: 15px;
        right: 15px;
    }
}