.csp_content{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 25vh;
  
}

.csp_cards_grid{
    margin-top: 95.1px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 69px;
    width: 100%;
    max-width: 1035px;
}

@media only screen and (max-width: 1035px){
    .csp_cards_grid{
        margin-top: 95.1px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
       
        width: 100%;
       
    }
}