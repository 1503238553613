.paragraph_text{
    width: 100%;
    max-width: 436px;
    height: 140px;
    left: 803px;
    top: 333px;

    font-family: 'Qualion';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 146.3%;
    /* or 20px */

    text-align: left;
    color: #FFFFFF;

}



@media only screen and (max-width:1050px){
    .paragraph_text{
        text-align: center;
      
    }
}

@media only screen and (max-width:690px){
    .paragraph_text{
        text-align: center;
        font-size: 14px;
        width: 85%;
    }
}