.byline_text{
    width: 388px;
    height: 24px;
    left: 803px;
    top: 293px;
    text-align: left;
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
      
}


@media only screen and (max-width:1050px){
    .byline_text{
        text-align: center;
        max-width: 388px;
        width: 100%;
    }
}