.home_hero_section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 125px;
}

.home_hero_contents {
  max-width: 1005px;
  min-width: 1000px;
  width: 100%;
  height: 826px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.home_hero_right_items {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 45px;
}

.home_hero_left_items{
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media only screen and (max-width: 1050px) {
  .home_hero_contents {
    overflow-x: hidden;
    flex-direction: column-reverse;
    margin-top: 15px;
    height: 100vh;
    min-width: 100%;
    gap: 15px;
  }

  .home_hero_right_items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 45px;
  }
}
