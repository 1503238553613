.cs_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25vh;
  height: 2095px;
  gap: 17px;
}

.case_study_image {
  width: 100%;
  height: auto;
  padding-bottom: 20%;
}
.cs_central_paragraph {
  width: 100%;
  max-width: 995.771px;
  color: #fff;
  text-align: center;
  font-family: Qualion;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 23px;
  /* padding-top: 17px;
  margin-bottom:65px; */
}

.cs_central_paragraph span {
  color: #ff3d00;
  font-family: Qualion;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.list_text_with_image {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  max-width: 1035px;
  gap: 35px;
}
.role_parent {
  display: flex;
  flex-direction: column;
  gap: 13px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.role_list_items {
  display: flex;
  justify-content: center;
  text-wrap: nowrap;
  gap: 16px;
}
.list_items_container {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}
.case_study_image_container {
  overflow: hidden;
  width: 100%;
  height: auto;
  border-radius: 35px;
  border: 2px solid #ff7798;
  width: 1035px;
  height: 314px;
  flex-shrink: 0;
  box-shadow: 0px 13.53066px 118.39324px 0px rgba(0, 0, 0, 0.35) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.primary_section_shm .cs_lower_items_container {
  width: 100%;
  max-width: 1035px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.grid-container .cs_list_object {
  max-width: 500px;
}
.grid-container .styled_image_display_container_small {
  height: 191.96px !important;
  display: flex;
  align-items: center;
  padding-bottom: 0%;
}
.desc_image_row .styled_image_display_container_small {
  height: 191.96px !important;
  display: flex;
  align-items: center;
  padding-bottom: 0%;
  width: 38%;
}
.desc_image_row .styled_image_display_container_small:nth-child(2) {
  height: 191.96px !important;
  display: flex;
  width: 16%;
  align-items: center;
  padding-bottom: 0%;
}
.grid-container .styled_image_display {
  width: 100%;
  height: auto;
  padding-bottom: 0%;
}
s .grid-item {
  background-color: #eaeaea06;
  padding: 20px;
  text-align: center;
}

.top-item {
  grid-column: span 1; /* Each top item spans one column */
}

.span-bottom {
  grid-column: span 1; /* Each bottom item spans one column */
}

.top-item:nth-child(1) {
  grid-column: 1 / span 2; /* First top item spans two columns */
}

.top-item:nth-child(2) {
  grid-column: 3 / span 2; /* Second top item spans two columns starting from column 3 */
}

.desc_container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 85px;
  margin-top: 85px;
  padding-bottom: 85px;
  width: 1035px;
}

.desc_image_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 28px;
  max-width: 1035px;
}

.grid_container {
}
.cs_list_items_row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.separator_container {
  margin-top: 36px;
  margin-bottom: 36px;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.separator_icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  width: 100%;
}
.separator_icon * {
  position: absolute;
}
.primary_section_shm {
  display: flex;
  width: 100%;
  max-width: 1035px;
  flex-wrap: wrap;
  gap: 46px;
  padding-top: 35px;
}
.grid_list {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 30px;
  grid-row-gap: 90px;
  padding-bottom: 75px;
  padding-top: 45px;
}

.grid_list .cs_list_object {
  max-width: 100%;
}
.cs_list_object_secondary_version {
  margin-top: 63px;
  margin-bottom: 130px;
}
@media only screen and (max-width: 1035px) {
  .role_list_items {
    flex-wrap: wrap;
  }
  .cs_content {
    width: 90%;
  }
  .case_study_image_container {
    width: 90vw;
  }
  .case_study_image {
    width: auto;
    height: 100%;
    padding-bottom: 20%;
  }
  .separator_icon {
    overflow: hidden;
    width: 90vw;
    height: 30px;
    position: absolute;
  }
  .cs_list_items_row {
    flex-direction: column;
    align-items: center;
    gap: 55px;
  }
  .primary_section_shm {
    display: flex;
    width: 100%;
    align-items: center;
    max-width: 100vw;
    flex-wrap: wrap;
    justify-content: center;
    gap: 46px;
    padding-top: 35px;
}

  .desc_container {
    width: 100vw;
    align-items: center;
    justify-content: center;
  }

  .cs_lower_items_container {
    width: 100%;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
  }

  .list_text_with_image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    max-width: 90vw;
    gap: 5px;
  }

  .cs_list_paragraph {
    text-align: center;
    padding-left: 0px;
  }
  .grid-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    width: 100%;
    flex-direction: column;
  }
  .desc_image_row {
    flex-direction: column;
  }

  .grid_list{
 
      display: flex;
      width: 100%;
      flex-direction: column;
      /* grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); */
      gap: 30px;
      grid-row-gap: 90px;
      padding-bottom: 75px;
      padding-top: 45px;
  
  }



  .cs_list_header_with_icon h1 {

    text-wrap: wrap;
}


.cs_list_header_with_icon {
  display: flex;
  gap: 22px;
  flex-direction: column;
  justify-content: center;
  margin-right: 0px;
  align-items: center;
}


.grid_list .cs_list_object {
  gap: 25px;
}

.cs_list_object_secondary_version {
  margin-top: 23px;
  margin-bottom: 70px;
}
.list_items_container {
  display: flex;
  flex-direction: column;
  gap: 102px;
}


.desc_image_row .styled_image_display_container_small {
  /* height: 191.96px !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78%;
}


.styled_image_display {
  width: 100%;
  height: auto;
  padding-bottom: 20%;
}
.main_display .styled_image_display {
  width: auto;
  height: 100%!important;
  padding-bottom: 20%;
}

.desc_image_row .styled_image_display_container_small:nth-child(2) {
  height: 191.96px !important;
  display: flex;
  width: 78%;
  align-items: center;
  padding-bottom: 0%;
}

}
