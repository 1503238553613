.mini_project_card_parent{
    width: 129.2px;
    height: 157px;
    opacity: .5;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(228, 234, 255, 0.05);
    /* BLUE_SHADOW */
    filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22));
    border-radius: 15px;
    cursor: pointer;
    transition: all.5s ease-in-out;
}

.mini_project_card_parent:hover{

    filter: drop-shadow(0px 4px 45px rgba(12, 210, 237, .45));
    border: 1px solid rgba(228, 234, 255, .7);


}






.mini_project_card_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    height: 95%;
    gap: 10px;

}


.mini_project_card_image_container{
    height: 95.26302337646484px;
    width: 107.9375px;

    border-radius: 2.0442707538604736px;
    
   
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #7795FF;
    /* BLUE_SHADOW */

    /* filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22)); */
    border-radius: 15px;
}


.mini_project_card_image_container img{
    width:100%;
    height: auto;
}

.mini_project_card_contents .mini_project_card_lower_items{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 95%;
    gap: 4px;
}

.mini_project_card_project_title{
    text-align: left;
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    color: #FFFFFF;
    display: none;
}

.mini_project_card_project_description{
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #CDE0F1;
    font-size: 4.90625px;
    line-height: 8px;
    display: none;
    
}

.mini_project_card_lower_items .right_items{
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;  
    gap: 10px;
}
.mini_project_card_lower_items .right_items .tech_stack_array_container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.mini_project_card_lower_items .right_items .main_theme_button{
    padding: 2.45312px 9.40365px;
    gap: 4.09px;
    display: none;

}


@media only screen and (max-width:190px){


    .mini_project_card_parent {
        width: 45%;
        height: 270px;

        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(228, 234, 255, 0.05);
        filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22));
        
        cursor: pointer;
    }


    .mini_project_card_contents {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 90%;
        height: auto;
        gap: 10px;
    }



    .mini_project_card_image_container {
        height: 45%;
        max-height: 175px;
        width: 95%;
        border-radius: 5px;
        display: flex;
    
        justify-content: center;
        overflow: hidden;
        border: 1px solid #7795FF;
        /* filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22)); */
        border-radius: 5px;
    }




.mini_project_card_image_container img {
    width: 100%;
    height: auto;
}

.mini_project_card_contents .mini_project_card_lower_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 95%;
    gap: 15%;
}

.mini_project_card_project_title {
    text-align: center;
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
}

.mini_project_card_project_description {
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #CDE0F1;
}


.right_items {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 10px;
}

}