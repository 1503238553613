.cs_small_list_object {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 329.5px;

}
.cs_small_list_header_with_icon{
display: flex;
gap: 22px;
align-items: flex-start;
}
.cs_small_list_header_with_icon h1{
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: Qualion;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.72px;
    margin-bottom: 5.5px;
}
.cs_small_list_paragraph{
    color: #FFF;
    font-family: Qualion;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    width: 100%;
    max-width: 279px;
    text-align: left;
  
}
.cs_small_list_header_text{
    color: white;
}

.cs_small_list_list >* {
    color: #FFF;
    font-family: Qualion;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    width: 100%;
    max-width: 279px;
    text-align: left;
    padding-left: 52px;
}