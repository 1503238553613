body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: "ModernPrestige";
  src: local("ModernPrestige"),
   url("./assets/fonts/ModernPrestige.otf") format("opentype");
  
  }

@font-face {
  font-family: "QualionUltraThin";
  src: local("Qualion-UltraThin"),
    url("./assets/fonts/Qualion-UltraThin.ttf") format("truetype");
  
  }
  
@font-face {
  font-family: "Qualion";
  src: local("Qualion"),
    url("./assets/fonts/Qualion-Regular.ttf") format("truetype");
  
  }

  @font-face {
    font-family: "QualionBook";
    src: local("QualionBook"),
      url("./assets/fonts/Qualion-Book.ttf") format("truetype");
    
    }
  @font-face {
    font-family: "Imprima";
    src: local("Imprima"),
      url("./assets/fonts/Imprima-Regular.ttf") format("truetype");
    
    }
  @font-face {
    font-family: "MBFLigioneRegular";
    src: local("MBFLigioneRegular"),
      url("./assets/fonts/MBFLigioneRegular.ttf") format("truetype");
    
    }
  
    @font-face {
      font-family: "ImperialScriptRegular";
      src: local("ImperialScriptRegular"),
        url("./assets/fonts/ImperialScript-Regular.ttf") format("truetype");
      
      }
    