

.csp_product_card_container{
    display: flex;
    width: 412px;
    height: 294px;
    padding: 0px 0.188px 23.626px 0.188px;
    flex-direction: column;
    align-items: center;
    gap: 33.037px;
    overflow: hidden;
    transition: all .4s ease;
}

.csp_product_card_container:hover{
    transform: scale(1.01);
}
.csp_product_card_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.outlined_background{
    position: absolute;
}

.csp_card_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    flex-direction: column;
}
.csp_lower_items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8%;
    width: 90%;
}

.csp_left_items{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.csp_lower_items > *{
    text-align: left;
}
.csp_product_card_image_container{
    overflow-x: hidden;
    border-radius: 15px;
    height: 191.963px;
border: 1.223px solid #FF7798;

box-shadow: 0px 8.27194px 72.37949px 0px rgba(0, 0, 0, 0.35) inset, 0px 2.44539px 2.44539px 0px rgba(0, 0, 0, 0.25);
}
.csp_product_card_image_container img {
  
  width: 105%;
}
