.gallery_tab_parent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery_tab {
  color: white;
  leading-trim: both;
  text-edge: cap;
  font-family: "QualionBook";
  font-size: 20px;
  font-style: normal;
  font-weight: 350;
  line-height: normal;
  letter-spacing: 1px;
  transition: all .4s ease;
  text-wrap: none;
}
.gallery_tab:hover{
    color: #d38bff;
   
}
/* .gallery_tab:hover >*{
    width: 35px;
   
} */

.gallery_tab_parent:hover .blur_div {
    width: 100%;
    height: 5.98px;
    background: #7328ad;
    filter: blur(17.5px);
}

.blur_div {
    width: 0px;
    height: 5.98px;
    background: #7328ad;
    filter: blur(17.5px);
    transition: all .4s ease;
}

@media only screen and (max-width: 690px) {
  .gallery_tab {
    max-width: 418px;
    width: 100%;
    /* font-size: 50px; */
    margin-right: 18px;
  }
}
