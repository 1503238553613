.wh_object{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 15px;
max-height: 62px;
flex-shrink: 0;
border-radius: 5px;
border: 1px solid #2C3A41;
}

.wh_left_items{
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.wh_image{
    height: 35px;
    width: auto;
}

.wh_left_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

}

.wh_left_text h2{
    text-align: left;
    text-wrap: nowrap;
}
.wh_right_items{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.wh_right_items h2{
    margin-top: -5px;
    text-align: center;
}

