.project_gallery_card_parent{
    height: 208.37px;
    width: 280px;

    border-radius: 2.95px;
    
    left: 239px;
    top: 164px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(228, 234, 255, 0.05);
    /* BLUE_SHADOW */
    filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22));
    border-radius: 5px;
    cursor: pointer;
    transition: all.5s ease-in-out;
}
.project_gallery_card_contents{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 95%;
    gap: 10px;

}


.project_gallery_card_image_container{
    height: 233px;
    width: 264px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    border: 1px solid #7795FF;
    /* BLUE_SHADOW */
    /* filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22)); */
    border-radius: 5px;
    transition: all.5s ease-in-out;
    height: 135.56px;
    width: 266.38px;
    border-radius: 2.95px;
    



}


.project_gallery_card_image_container img{
    width:100%;
    height: auto;
    transition: all.5s ease-in-out;
}

.project_gallery_card_contents .lower_items{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 95%;
}

.project_gallery_title{
    text-align: left;
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
}

.project_gallery_description{
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.005em;
    text-align: left;
    color: #CDE0F1;
    
}

.right_items{
    align-self: center;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    height: 100%;  
    gap: 10px;
}


.project_gallery_card_parent:hover{

    filter: drop-shadow(0px 4px 45px rgba(12, 210, 237, .45));
    /* border: 2px solid rgba(228, 234, 255, .5); */


}
.project_gallery_card_parent:hover .project_gallery_card_image_container{

    border: 1px solid rgba(0, 110, 255, 0.356);

}


.project_gallery_card_parent:hover .project_gallery_card_image_container img{
transform: scale(1.05);
opacity: .7;
}


.project_gallery_card_image_container img{
    width:100%;
    height: auto;
}

@media only screen and (max-width:690px){


    .project_gallery_card_parent {
        width: 45%;
        height: 353px;
        left: 239px;
        top: 164px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid rgba(228, 234, 255, 0.05);
        filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22));
        border-radius: 5px;
        cursor: pointer;
    }


    .project_gallery_card_contents {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 90%;
        height: 95%;
        gap: 10px;
    }



    .project_gallery_card_image_container {
        height: 45%;
        max-height: 233px;
        width: 95%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: 1px solid #7795FF;
        /* filter: drop-shadow(0px 4px 35px rgba(12, 210, 237, 0.22)); */
        border-radius: 5px;
    }




.project_gallery_card_image_container img {
    width: 100%;
    height: auto;
}

.project_gallery_card_contents .lower_items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 95%;
    gap: 15%;
}

.project_gallery_title {
    text-align: center;
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
}

.project_gallery_description {
    font-family: 'Qualion';
    font-style: normal;
    font-weight: 350;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: 0.005em;
    text-align: center;
    color: #CDE0F1;
}


.right_items {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    gap: 10px;
}

}