.secondary_theme_button{

    box-sizing: border-box;
    cursor: pointer;
    /* Auto layout */
    width: 132px;
    height: 40px;

    font-family: 'Qualion';
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 3px;
    
    background: var(--theme-gradient-3);
    border: 0.5px solid #1673FF;
    border-radius: 35px;

    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    gap: 5px;
    transition: all .2s ease-in-out;
}


.secondary_theme_button span{
    margin-top: -3px;
}
.secondary_theme_button:hover{
    background: white;
    color: black;
}

.button_icon{
    height: 24px;
    width: 24px;
  
    
}