.main_header{
    width: 418px;


    font-family: 'Modern Prestige DEMO';
    font-style: italic;
    font-weight: 400;
    font-size: 65px;
    line-height: 72px;
    /* identical to box height */


    align-items: center;

    color: #FFFFFF;

    font-family: "ModernPrestige";
    color: white;
}

@media only screen and (max-width:690px){

    .main_header{
        max-width: 418px;
        width: 100%;
        font-size:50px;
        margin-right: 18px;
    
    }


}