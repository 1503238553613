.nav_bar{
    position: absolute;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

}


.nav_bar_contents{
    width: 70%;
    height: 30px;
    /* left: 189px;
    top: 44px; */
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.nav_bar_left_items{
    display: flex;
    gap: 15px;
}

.nav_bar_right_items{
    display: flex;
    gap: 15px;
    
}

@media only screen and (max-width:480px){
    .nav_bar{
        position: absolute;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    
    }
    
    
    .nav_bar_contents{

        justify-content: center;
    }
    
    .nav_bar_left_items{
   
    }
    
    .nav_bar_right_items{
        
        display: none;
    }
    
    
}