.project_carousel_parent{
    height: 80vh;
    min-width: 25vw;
    width: 100%;
    align-items: flex-end;
    padding-right: 15px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap:45px;
    
 
}
.project_carousel_parent::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .project_carousel_parent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.253); 
    border-radius: 10px;
  }
   
  /* Handle */
  .project_carousel_parent::-webkit-scrollbar-thumb {
    background: rgba(0, 68, 255, 0.144); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .project_carousel_parent::-webkit-scrollbar-thumb:hover {
    background: #006bb371; 
  }



@media only screen and (max-width:1050px){
    /* .project_carousel_parent{
        height: auto;
        width: auto;
        display: flex;
        flex-direction: row;
        gap:45px;
     
    } */


    .project_carousel_parent {
        height: auto;
        width: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        gap: 2%;
        justify-content: center;
    }
    
}


@media only screen and (max-width:690px){



  .project_carousel_parent {
      height: auto;
      width: auto;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 25px;
      justify-content: center;
  }
  
}