.gallery_overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.98);
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.gallery_content .exit_button {
    top: 5%;
    right: 5%;
}
.gallery_content{
    width: 100%;
    max-width: 1155px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0vh;
    height: 100vh;
    gap: 52px;
}
.gallery_top_items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
}

.gallery_lower_items{
    display: flex;
    width: 100%;
}
.gallery_content .gallery_lower_items .gallery_left_items{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.gallery_lower_items  .navigation_button_reverse{
    margin-left:52px;
    margin-top: 22px;
}
.gallery_left_top_items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
}

.gallery_left_top_items >*{
    text-align: left;
}
.gallery_content .gallery_right_items{
    width: 50%;
    height: 100%;
}

.gallery_content .styled_image_display_container{
    width: 100%;
    max-width: 573px;
}

.header_with_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    margin-left: -35px;

}

.icon_container >*{
    width: 26.45px;
}
.gallery_overlay .cs_list_object_secondary_version{
margin: 0px;
gap: 32px;
  }
/* .gallery_overlay .cs_list_paragraph {

    padding-left: 52px;
    padding-top: 0px;
  } */