.tertiary_theme_button{

    box-sizing: border-box;
    cursor: pointer;
    height: 40px;
    font-family: 'Qualion';
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 3px;
    padding-left: 25px;
    padding-right: 25px;
    background: linear-gradient(256.31deg, rgba(12, 75, 237, 0.05) -6.18%, rgba(12, 210, 237, 0.05) 113.91%);
    border: 0.5px solid rgba(22, 115, 255, 0.05);
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    gap: 10px;
    transition: all.5s ease-in-out;
}

.tertiary_theme_button span{
    margin-top: -3px;
}
.tertiary_theme_button:hover{
    background: white;
    color: black;
}

.button_icon{
    height: 24px;
    width: 24px;
  
  
    
}


