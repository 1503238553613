.contact_overlay_parent{
    height: 100vh;
    width: 100vw;
    background-color: black;
    z-index: 20;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

}
.contact_overlay_parent .exit_button{
    right: 30%;
    top: 15%;
}

.detail_parent{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 20%;
    transition: all.5s ease-in-out;
}

.detail_parent:hover{
    opacity: .5;
    cursor: pointer;
}
.detail_top_items{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-family: "Qualion";

}
.detail_top_items span{

    font-weight: 900;
}


.detail_top_items .button_icon{
    color: white;
    height: 50px;
    width: 50px;
}


.center_icon{
    position: absolute;
    height: 50px;
    width: 50px;
    display: none;
}
.rounded_line{
    height: 2.5px;
    border-radius: 15px;
    background-color: white;
    width: 100%;
}