.project_overlay_button{
    display: inline-flex;
    /* padding: 4px 10px 4px 19px; */
    align-items: center;
    gap: 10px;
 
 
    color: white;
    cursor: pointer;
    transition: all .15s ease-in-out;
    border: 0px solid white;


    padding: 3px 10px 8px 19px;
    gap: 10px;

    position: relative;

    height: 32px;

    font-family: 'Qualion';
    border-radius: 35px;
    border: 1px solid #195170;
    background: linear-gradient(256deg, rgba(12, 75, 237, 0.15) -6.18%, rgba(12, 210, 237, 0.15) 113.91%);

    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Qualion;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}


.project_overlay_button:hover{
    background-color: white;
    color: black;
}

.project_overlay_button .button_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.button_icon div svg{
    color: black;
    height: 21px;
    width: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
 
}


.project_overlay_button:hover .button_icon div svg *{

    fill: black;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width:690px){

 

}
